
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import content from './content'

interface ArticlePdf {
  title?: String
  content?: String
}
export default defineComponent({

  setup() {
    const article = ref<ArticlePdf>({})
    const dataMap = reactive({
      fullscreenLoading: true,
      fetchData: () => {
        const { title } = content
        document.title = title
        article.value = content

        setTimeout(() => {
          dataMap.fullscreenLoading = false
        }, 2000)

        setTimeout(() => {
          window.print()
        }, 2600)
      }
    })

    onMounted(() => {
      dataMap.fetchData()
    })
    return { ...toRefs(dataMap), article }
  }
})
